
import { defineComponent, ref, onMounted, computed } from 'vue';
import AuthService from '@/services/AuthService';
import CommonLayout from '@/components/layouts/CommonLayout.vue';
import Footer from '@/components/UI/Footer.vue';
import LayoutService from '@/services/LayoutService';
import { LayoutMode } from './interfaces';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

export default defineComponent({
	name: 'App',
	components: {
		CommonLayout,
		Footer,
	},
	setup() {
		const { locale } = useI18n();
		const contentLoaded = ref(false);
		const store = useStore();
		const route = useRoute();

		onMounted(async () => {
			// Pre-loading data
			if (AuthService.isLoggedIn()) {
				LayoutService.switchLayout(LayoutMode.Frontend);
				await store.dispatch('setup', locale.value);
			}
			contentLoaded.value = true;
		});

		const hideFooter = computed(() => {
			return route.meta.hideFooter;
		});

		return {
			contentLoaded,
			hideFooter,
		};
	},
});
