import { LayoutMode } from "@/interfaces";
import store from '@/store';

class LayoutService {
    constructor() {}

    switchLayout(mode: LayoutMode): void {
        store.commit('setLayoutMode', mode);
    }
}

export default new LayoutService();