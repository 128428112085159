import { ComputedRef, Ref } from "vue";
import { Athlete, Federation, Organization, RouteEntity, Trainer } from ".";

export interface BreadcrumbItem {
    label: string;
    path: string;
    type: BreadcrumbStructureItem;
    isBackoffice?: boolean;
    entityType?: RouteEntity;
    data?: {
        federation?: Federation;
        organization?: Organization;
        trainer?: Trainer;
        athlete?: Athlete;
    }
}

export interface BreadcrumbInstance {
    items: ComputedRef<BreadcrumbItem[]>;
    history: BreadcrumbItem[];
    rebuild: Ref<boolean>;
    push: (item: BreadcrumbItem) => void;
    sub: (itemIndex: number) => void;
}

export enum BreadcrumbStructureItem {
    Menu = 'menu',
    Federation = 'federation',
    Organization = 'organization',
    Trainer = 'trainer',
    Athlete = 'athlete'
}

export interface EntityBreadcrumbStructure {
    backofficeMenuPath: BreadcrumbItem;
    frontendMenuPath: BreadcrumbItem;
}