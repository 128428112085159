
	import {
		i18nLanguages,
		i18n,
		switchI18nLanguage,
		localizePath,
		currentI18nLanguage,
	} from '@/plugins/i18n';
	import { computed, defineComponent, reactive, ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { useRoute, useRouter } from 'vue-router';
	import { MrUiKitButton } from 'mr-ui-kit';

	export default defineComponent({
		name: 'Footer',
		components: {
			MrUiKitButton,
		},
		setup() {
			const { t, locale } = useI18n();
			const route = useRoute();
			const router = useRouter();
			const showLanguages = ref(false);
			const columns = reactive([
				{
					label: computed(() => t('common.federations')),
					path: '/federations',
				},
				{
					label: computed(() => t('common.organizations')),
					path: '/organizations',
				},
				{
					label: computed(() => t('common.trainers')),
					path: '/trainers',
				},
				{
					label: computed(() => t('common.athletes')),
					path: '/athletes',
				},
			]);
			const socials = reactive([
				{
					type: 'facebook',
					url: 'https://www.facebook.com/sanmarinonocs/',
				},
				{
					type: 'twitter',
					url: 'https://twitter.com/nocsanmarino',
				},
				{
					type: 'instagram',
					url: 'https://www.instagram.com/smrolympicteam/',
				},
				{
					type: 'youtube',
					url: 'https://www.youtube.com/channel/UCuBuk8nyaF7EMFYvCW5Cmag',
				},
			]);

			const goTo = (path: string) => {
				router.push(localizePath({ path }));
			};

			const getLanguageIcon = (selectedLocale: 'en' | 'it') => {
				return require('@/assets/img/languages/' + selectedLocale + '.png');
			};

			const switchLanguage = (newLocale: 'en' | 'it') => {
				locale.value = newLocale;
				i18n.global.locale = newLocale;
				showLanguages.value = false;
				switchI18nLanguage(newLocale);
			};

			return {
				t,
				goTo,
				getLanguageIcon,
				switchLanguage,
				footerLogo: require('@/assets/img/logo_footer.png'),
				columns,
				socials,
				supportedLanguages: i18nLanguages,
				currentI18nLanguage,
				showLanguages,
			};
		},
	});
