import { AlertCfg, BreadcrumbItem, BreadcrumbStructureItem, CompetitionResultType, GeneralFormatted, GeneralPermission, LayoutMode, RootState, RouteEntity, Sport, SupportedLanguage } from '@/interfaces';
import { ActionTree, createStore, GetterTree, MutationTree } from 'vuex';
import SetupService from '@/services/SetupService';
import axios from 'axios';

const breadcrumbStructure: {[k: string]: number} = {
  [`${BreadcrumbStructureItem.Menu}`]: 0,
  [`${BreadcrumbStructureItem.Federation}`]: 1,
  [`${BreadcrumbStructureItem.Organization}`]: 2,
  [`${BreadcrumbStructureItem.Trainer}`]: 3,
  [`${BreadcrumbStructureItem.Athlete}`]: 4
}

const state: RootState = {
  layoutMode: LayoutMode.None,
  isLoading: false,
  isSuperAdmin: false,
  isVisitor: false,
  generalPermissions: {},
  languages: [],
  sports: [],
  staffRoles: [],
  trainerRoles: [],
  competitionResultTypes: [],
  breadcrumb: {
    rebuild: true,
    history: [],
    backofficeMenuPath: {
      path: '/account',
      label: 'Account',
      type: BreadcrumbStructureItem.Menu,
      data: {}
    },
    frontendMenuPath: {
        path: '/',
        label: 'Home',
        type: BreadcrumbStructureItem.Menu,
        data: {}
    },
    current: 5 // max
  },
  alert: {
    toggle: false,
    message: null,
    type: null
  }
}

const mutations: MutationTree<RootState> = {
  setLayoutMode(state: RootState, layoutMode: LayoutMode) {
    state.layoutMode = layoutMode;
  },
  setLoading(state: RootState, isLoading: boolean) {
    state.isLoading = isLoading;
  },
  setLanguages(state: RootState, languages: SupportedLanguage[]) {
    state.languages = languages;
  },
  setGeneralPermissions(state: RootState, generalPermissions: Partial<GeneralPermission>) {
      state.generalPermissions = {...state.generalPermissions, ...generalPermissions};
  },
  addSports(state: RootState, sports: Sport) {
    state.sports.push(sports);
  },
  setSports(state: RootState, sports: Sport[]) {
    state.sports = sports;
  },
  setStaffRoles(state: RootState, roles: GeneralFormatted[]) {
    state.staffRoles = roles;
  },
  setCompetitionResultTypes(state: RootState, types: CompetitionResultType[]) {
    state.competitionResultTypes = types;
  },
  setTrainerRoles(state: RootState, roles: GeneralFormatted[]) {
    state.trainerRoles = roles;
  },
  setAlert(state: RootState, cfg: AlertCfg) {
    state.alert = Object.assign(state.alert, {
      ...cfg,
      toggle: cfg.message != null
    });
  },
  setSuperAdmin(state: RootState, isSuperAdmin: boolean) {
    state.isSuperAdmin = isSuperAdmin;
  },
  setVisitor(state: RootState, isVisitor: boolean) {
    state.isVisitor = isVisitor;
  },
  pushBreadcrumb(state: RootState, item: BreadcrumbItem) {
    state.breadcrumb.current = breadcrumbStructure[item.type];
    if (state.breadcrumb.history.length > 0) {
      state.breadcrumb.rebuild = false;
    }
    if (state.breadcrumb.rebuild === true) {
      console.log(item);
      state.breadcrumb.rebuild = false;
      const home = item.isBackoffice === true ? state.breadcrumb.backofficeMenuPath : state.breadcrumb.frontendMenuPath;
      const endPath = item.isBackoffice === true ? '/edit' : '';
      state.breadcrumb.history.push(home);
      switch(item.entityType) {
          case RouteEntity.Organization:
              if (item.data && item.data.organization) {
                  state.breadcrumb.history.push({
                    path: '/federations/' + item.data.organization.federation.id + endPath,
                    label: item.data.organization.federation.fullname,
                    type: BreadcrumbStructureItem.Federation
                  });
              }
              break;
          case RouteEntity.Trainer:
              if (item.data && item.data.trainer) {
                  if (item.data.trainer.federations.length > 0) {
                      state.breadcrumb.history.push({
                          path: '/federations/' + item.data.trainer.federations[0].id + endPath,
                          label: item.data.trainer.federations[0].fullname,
                          type: BreadcrumbStructureItem.Federation
                      });
                  }

                  if (item.data.trainer.organizations.length > 0) {
                      state.breadcrumb.history.push({
                          path: '/organizations/' + item.data.trainer.organizations[0].id + endPath,
                          label: item.data.trainer.organizations[0].fullname,
                          type: BreadcrumbStructureItem.Organization
                      });
                  }
              }
              break;
          case RouteEntity.Athlete:
              if (item.data && item.data.athlete) {
                  if (item.data.athlete.federations.length > 0) {
                      state.breadcrumb.history.push({
                          path: '/federations/' + item.data.athlete.federations[0].id + endPath,
                          label: item.data.athlete.federations[0].fullname,
                          type: BreadcrumbStructureItem.Federation
                      });
                  }

                  if (item.data.athlete.organizations.length > 0) {
                      state.breadcrumb.history.push({
                          path: '/organizations/' + item.data.athlete.organizations[0].id + endPath,
                          label: item.data.athlete.organizations[0].fullname,
                          type: BreadcrumbStructureItem.Organization
                      });
                  }

                  if (item.data.athlete.trainers.length > 0) {
                      state.breadcrumb.history.push({
                          path: '/trainers/' + item.data.athlete.trainers[0].id + endPath,
                          label: item.data.athlete.trainers[0].firstname + ' ' + item.data.athlete.trainers[0].lastname,
                          type: BreadcrumbStructureItem.Trainer
                      });
                  }
              }
              break;
      }
    }
  
    state.breadcrumb.history.push(item);
  },
  setBreadcrumbRebuild(state: RootState) {
    state.breadcrumb.history = [];
    state.breadcrumb.rebuild = true;
  },
  subBreadcrumb(state: RootState, index: number) {
    state.breadcrumb.history.splice(index + 1, state.breadcrumb.history.length);
    if (index < 0) {
      state.breadcrumb.current = 5;
    } else {
      state.breadcrumb.current = breadcrumbStructure[state.breadcrumb.history[index].type];
    }
  },
  switchBreadcrumb(state: RootState, mode: 'frontend' | 'backoffice') {
    state.breadcrumb = {
      ...state.breadcrumb,
      history: state.breadcrumb.history.map(h => {
        switch (h.type) {
          case BreadcrumbStructureItem.Menu:
            const menu = mode === 'backoffice' ? state.breadcrumb.backofficeMenuPath : state.breadcrumb.frontendMenuPath;
            return menu;
          default:
            return {
              ...h,
              path: mode === 'backoffice' ? h.path + '/edit' : h.path.replace(new RegExp('/edit', 'g'), '')
            }
        }
      })
    }
  }
}

const getters: GetterTree<RootState, RootState> = {
  layoutMode(state: RootState): LayoutMode {
    return state.layoutMode;
  },
  supportedLanguages(state: any): SupportedLanguage[] {
    return state.languages;
  },
  sports(state: RootState): Sport[] {
    return state.sports;
  },
  trainerRoles(state: RootState) {
    return state.trainerRoles;
  },
  resultTypes(state: RootState): CompetitionResultType[] {
    return state.competitionResultTypes;
  },
  alertCfg(state: RootState): AlertCfg {
    return state.alert;
  },
  isSuperAdmin(state: RootState) {
    return state.isSuperAdmin;
  },
  isVisitor(state: RootState) {
    return state.isVisitor;
  },
  breadcrumbItems(state: RootState): BreadcrumbItem[] {
    const last = {
      home: state.breadcrumb.history.filter(h => h.type === BreadcrumbStructureItem.Menu),
      federation: state.breadcrumb.history.filter(h => h.type === BreadcrumbStructureItem.Federation),
      organization: state.breadcrumb.history.filter(h => h.type === BreadcrumbStructureItem.Organization),
      trainer: state.breadcrumb.history.filter(h => h.type === BreadcrumbStructureItem.Trainer),
      athlete: state.breadcrumb.history.filter(h => h.type === BreadcrumbStructureItem.Athlete)
    }
    const out: BreadcrumbItem[] = [];

    for (const key of Object.keys(last)) {
        const nItems = last[key as 'home' | 'federation' | 'organization' | 'trainer' | 'athlete'].length;
        if (nItems > 0) {
            const toPush = last[key as 'home' | 'federation' | 'organization' | 'trainer' | 'athlete'][nItems - 1];
            const toPushIndex = breadcrumbStructure[toPush.type];
            if (state.breadcrumb.current >= toPushIndex) {
              out.push(toPush);
            }
        }
    }
    return out;
  }
}

const actions: ActionTree<RootState, RootState> = {
  async setup({ commit, dispatch }, lang: string) {
    const response = await SetupService.setup();
    commit('setLanguages', response.languages);
    commit('setGeneralPermissions', response.generalPermissions);
    commit('setSuperAdmin', response.isSuperAdmin);
    commit('setVisitor', response.isVisitor);
    dispatch('getStaffRoles', lang);
    dispatch('getSports', lang);
    dispatch('getTrainerRoles', lang);
  },
  async getStaffRoles({ commit }, lang: string) {
    const { data } = await axios.get<GeneralFormatted[]>('/staff-roles?lang=' + lang);
    commit('setStaffRoles', data);
  },
  async getTrainerRoles({ commit }, lang: string) {
    const { data } = await axios.get<GeneralFormatted[]>('/trainer-roles?lang=' + lang);
    commit('setTrainerRoles', data);
    return data;
  },
  async getSports({ commit }, lang: string) {
    const { data } = await axios.get<Sport[]>('/sports?lang=' + lang);
    commit('setSports', data);
  },
  async getResultTypes({ commit }, lang: string) {
    const { data } = await axios.get<CompetitionResultType[]>('/result-types?lang=' + lang);
    commit('setCompetitionResultTypes', data);
  }
}

export default createStore({
  state,
  mutations,
  getters,
  actions
});
