import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from '@/plugins/i18n';
import 'mr-ui-kit/dist/mr-uikit.css';
import MrUiKit from 'mr-ui-kit';
import 'bootstrap';

import '@/plugins/axios';

createApp(App)
	.use(i18n)
	.use(store)
	.use(MrUiKit)
	.use(router)
	.mount('#app');
