import { Count, Filter, FormattedFilterResult, GeneralFormatted, RouteEntity } from "@/interfaces";
import axios from "axios";

class FilterService {
    constructor() {}

    parseFilter(filter: any): string {
        let out: string = '';
        if (filter != null) {
            for (let i = 0; i < Object.keys(filter).length; i++) {
                const key = Object.keys(filter)[i];
                if (filter[key] != null) {
                    out += `filter[${key}]=${filter[key]}`;
                }
    
                // Add & if there more values
                if ((Object.keys(filter).length - i) !== 1 && (filter[key] != null)) {
                    out += '&';
                }
            }
    
            if (out[out.length - 1] === '&') {
                out = out.slice(0, -1);
            }
        }
        return out;
    }

    async count(search: string): Promise<Count> {
        const { data } = await axios.get<Count>('/filters/count?keyword=' + search);
        return data;
    }

    async getByKeywords(search: string, language: string, filter: Filter): Promise<FormattedFilterResult[]> {
        const parsedFilter = this.parseFilter(filter);
        const { data } = await axios.get<FormattedFilterResult[]>('/filters?keyword=' + search + '&lang=' + language + '&' + parsedFilter);
        return data;
    }

    async findByEntity(entity: RouteEntity, keyword: string, language: string, filter: Filter): Promise<GeneralFormatted[]> {
        const parsedFilter = this.parseFilter(filter);
        const { data } = await axios.get<GeneralFormatted[]>('/entities/' + entity + '/find?keyword=' + keyword + '&lang=' + language + '&' + parsedFilter);
        return data;
    }
}

export default new FilterService();