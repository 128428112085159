
import { defineComponent, inject, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { MrUiKitSearch } from 'mr-ui-kit';
import { BreadcrumbInstance, BreadcrumbStructureItem, FormattedFilterResult, Organization, RouteEntity } from '@/interfaces';
import FilterService from '@/services/FilterService';
import { useRouter } from 'vue-router';
import { localizePath } from '@/plugins/i18n';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'TopSearch',
    components: {
        MrUiKitSearch
    },
    setup() {
        const { t, locale } = useI18n();
        const router = useRouter();
        const store = useStore();
        const search = ref('');
        const items = ref<FormattedFilterResult[]>([]);

        const onClick = async (item: FormattedFilterResult) => {
            router.push(localizePath({
                path: '/' + item.entity + '/' + item.id
            }));

            let type = BreadcrumbStructureItem.Federation;
            switch (item.entity) {
                case RouteEntity.Federation:
                    type = BreadcrumbStructureItem.Federation;
                    break;
                case RouteEntity.Organization:
                    type = BreadcrumbStructureItem.Organization;
                    break;
                case RouteEntity.Trainer:
                    type = BreadcrumbStructureItem.Trainer;
                    break;
                case RouteEntity.Athlete:
                    type = BreadcrumbStructureItem.Athlete;
                    break;
            }
            
            store.commit('pushBreadcrumb', {
                label: item.fullname,
                path: '/' + item.entity + '/' + item.id,
                type
            });

            // Force menu close menu
            items.value = [];
        }

        const onSearch = async (searchData: any) => {
            if (searchData.value === '') {
                items.value = [];
            } else {
                items.value = await FilterService.getByKeywords(searchData.value, locale.value, {
                    limit: 10,
                    offset: searchData.offset
                });
            }
        }

        const onShowMoreResults = () => {
            router.push(localizePath({
                path: '/search',
                query: {
                    keyword: search.value
                }
            }))

            items.value = [];
        }

        const organizations = (itemOrganizations: Organization[]) => {
            return itemOrganizations.splice(0, 3);
        }

        return {
            t,
            onClick,
            onSearch,
            onShowMoreResults,
            organizations,
            search,
            items
        }
    },
})
