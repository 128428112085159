import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "common-layout" }
const _hoisted_2 = {
  key: 1,
  class: "common-layout-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitNavbar = _resolveComponent("MrUiKitNavbar")!
  const _component_MrUiKitAlert = _resolveComponent("MrUiKitAlert")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.layoutConfig.hideNavBar)
      ? (_openBlock(), _createBlock(_component_MrUiKitNavbar, {
          key: 0,
          items: _ctx.tabs,
          logo: _ctx.logoHeader,
          onNavigate: _ctx.onNavigate,
          fixed: true,
          enableFading: _ctx.isFadingEnabled,
          fading: _ctx.fading,
          classes: ['container-90']
        }, null, 8, ["items", "logo", "onNavigate", "enableFading", "fading"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MrUiKitAlert, {
      modelValue: _ctx.alert.toggle,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.alert.toggle) = $event)),
      timeout: 4000,
      type: _ctx.alert.type,
      classes: ['position-fixed', 'alert-position', 'w-25']
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.alert.message), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "type"]),
    (_ctx.layoutConfig.layout === _ctx.noneLayout)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_router_view)
        ]))
      : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layoutConfig.layout + 'Layout'), {
          key: 2,
          class: _normalizeClass({ 'page-margin': !_ctx.layoutConfig.isNavBarSoft })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }, 8, ["class"]))
  ]))
}