import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b6f3c6da"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "cons-frontend",
  class: "flex-wrapper"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.contentLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_router_view)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.hideFooter && _ctx.contentLoaded)
      ? (_openBlock(), _createBlock(_component_Footer, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}