import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "top-search" }
const _hoisted_2 = { class: "bg-primary-custom-cons" }
const _hoisted_3 = { class: "container py-4" }
const _hoisted_4 = { class: "w-lg-75 mx-lg-auto" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex-shrink-0" }
const _hoisted_7 = { class: "avatar avatar-circle" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "avatar-img",
  src: "https://htmlstream.com/preview/front-v4.3.1/assets/img/160x160/img1.jpg"
}
const _hoisted_10 = { class: "flex-grow-1 ms-3 mb-0" }
const _hoisted_11 = { class: "mb-0" }
const _hoisted_12 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_13 = {
  key: 0,
  class: "ms-2 badge bg-soft-primary text-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MrUiKitSearch = _resolveComponent("MrUiKitSearch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_MrUiKitSearch, {
            placeholder: _ctx.t('home.searchBarPlaceholder'),
            infiniteScroll: false,
            modelValue: _ctx.search,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
            searchResults: _ctx.items,
            onOnSearch: _ctx.onSearch,
            classes: ['border']
          }, {
            default: _withCtx(({ item }) => [
              _createElementVNode("div", {
                onClick: ($event: any) => (_ctx.onClick(item)),
                class: "d-flex align-items-center p-2"
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (item.photo)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "avatar-img",
                          src: item.photo
                        }, null, 8, _hoisted_8))
                      : (_openBlock(), _createElementBlock("img", _hoisted_9))
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("h5", _hoisted_11, _toDisplayString(item.fullname), 1),
                  (item.organizations)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizations(item.organizations), (org) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: 'top-search-' + org.id,
                            class: "blockquote-footer-source"
                          }, _toDisplayString(org.fullname), 1))
                        }), 128)),
                        (item.organizations.length > 2)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, " + " + _toDisplayString(item.organizations.length - 3), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ], 8, _hoisted_5)
            ]),
            footer: _withCtx(() => [
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onShowMoreResults && _ctx.onShowMoreResults(...args))),
                class: "searchFooter"
              }, _toDisplayString(_ctx.t('common.showMoreResults')), 1)
            ]),
            _: 1
          }, 8, ["placeholder", "modelValue", "searchResults", "onOnSearch"])
        ])
      ])
    ])
  ]))
}