
import {
  computed,
  defineComponent,
  inject,
  onBeforeUpdate,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { MrUiKitNavbar, MrUiKitBreadcrumb, MrUiKitAlert } from "mr-ui-kit";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import TopSearch from "@/components/UI/TopSearch.vue";
import {
  BreadcrumbInstance,
  BreadcrumbStructureItem,
  LayoutMode,
} from "@/interfaces";
import FrontendLayout from "@/components/layouts/FrontendLayout.vue";
import BackofficeLayout from "@/components/layouts/BackofficeLayout.vue";
import { useStore } from "vuex";
import { localizePath } from "@/plugins/i18n";
import AuthService from "@/services/AuthService";
import LayoutService from "@/services/LayoutService";

export default defineComponent({
  name: "CommonLayout",
  components: {
    MrUiKitNavbar,
    TopSearch,
    MrUiKitBreadcrumb,
    FrontendLayout,
    BackofficeLayout,
    MrUiKitAlert,
  },
  setup() {
    const { t, locale } = useI18n();
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const fading = ref(false);
    const tabs = reactive([
      {
        label: computed(() => t("tabs.home")),
        path: "/",
      },
      {
        label: computed(() => t("tabs.federations")),
        path: "/federations",
        active: false,
      },
      {
        label: computed(() => t("tabs.organizations")),
        path: "/organizations",
      },
      {
        label: computed(() => t("tabs.trainers")),
        path: "/trainers",
      },
      {
        label: computed(() => t("tabs.athletes")),
        path: "/athletes",
      },
      {
        path: "/account",
        icon: { name: "bi-person-circle", zoom: 1.5 },
      },
    ]);

    onMounted(() => {
      window.addEventListener("scroll", scrollMethod);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", scrollMethod);
    });

    onBeforeUpdate(() => {
      scrollMethod();
    });

    const routeTabs = computed(() => {
      return tabs.map((t) => {
        return {
          ...t,
          active: route.path === "/" + locale.value + t.path,
        };
      });
    });

    const onNavigate = (path: string) => {
      router.push(localizePath({ path }));
      store.commit("subBreadcrumb", -1); // Reset
      store.commit("pushBreadcrumb", {
        label: "Account",
        path: "/account",
        type: BreadcrumbStructureItem.Menu,
        isBackoffice: true,
      });
    };

    const switchLocale = (language: string) => {
      locale.value = language;
    };

    const isFadingEnabled = computed(() => {
      return ![LayoutMode.Backoffice, LayoutMode.Frontend].includes(
        route.meta.layout as LayoutMode
      );
    });

    const layoutConfig = computed(() => {
      return {
        layout: route.meta.layout as LayoutMode,
        hideNavBar: route.meta.hideNavBar,
        isNavBarSoft: route.meta.isNavBarSoft,
        showTopSearch: route.meta.showTopSearch,
        hideBreadcrumb: route.meta.hideBreadcrumb,
      };
    });

    const scrollMethod = () => {
      // if (isFadingEnabled.value) {
      fading.value = pageYOffset < 25 ? false : true;
      // }
    };

    return {
      tabs: routeTabs,
      layoutConfig,
      isFadingEnabled,
      alert: store.getters.alertCfg,
      logoHeader: require("@/assets/img/logo_header.png"),
      switchLocale,
      onNavigate,
      noneLayout: LayoutMode.None,
      fading,
    };
  },
});
