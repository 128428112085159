import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AuthService from '@/services/AuthService';
import { LayoutMode, RouteMeta } from '@/interfaces';
import { defaultI18nLang, i18n, i18nSupportedLanguages, localizePath } from '@/plugins/i18n';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:locale',
    name: 'locale',
    component: () => import(/* webpackChunkName: "Logout" */ '../components/layouts/CommonLayout.vue'),
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale.toString();
      if (!i18nSupportedLanguages.includes(locale)) {
        i18n.global.locale = defaultI18nLang;
        return next(defaultI18nLang);
      }
      i18n.global.locale = locale as any;
      return next();
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/Login.vue'),
        meta: {
          hideNavBar: true,
          hideBreadcrumb: true,
          layout: LayoutMode.None,
          hideFooter: true
        } as RouteMeta
      },
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/Home.vue'),
        meta: {
          auth: true,
          isNavBarSoft: true,
          hideBreadcrumb: true,
          layout: LayoutMode.None
        } as RouteMeta
      },
      {
        path: 'federations/:id',
        name: 'federation',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/Federation.vue'),
        meta: {
          auth: true,
          showTopSearch: true,
          layout: LayoutMode.Frontend
        } as RouteMeta
      },
      {
        path: 'organizations/:id',
        name: 'organization',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/Organization.vue'),
        meta: {
          auth: true,
          showTopSearch: true,
          layout: LayoutMode.Frontend
        } as RouteMeta
      },
      {
        path: 'trainers/:id',
        name: 'trainer',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/Trainer.vue'),
        meta: {
          auth: true,
          showTopSearch: true,
          layout: LayoutMode.Frontend
        } as RouteMeta
      },
      {
        path: 'athletes/:id',
        name: 'athlete',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/Athlete.vue'),
        meta: {
          auth: true,
          showTopSearch: true,
          layout: LayoutMode.Frontend
        } as RouteMeta
      },
      {
        path: 'competitions/:id',
        name: 'competition',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/Competition.vue'),
        meta: {
          auth: true,
          showTopSearch: true,
          layout: LayoutMode.Frontend
        } as RouteMeta
      },
      {
        path: 'account',
        name: 'account',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/Account.vue'),
        meta: {
          auth: true,
          layout: LayoutMode.Backoffice,
          hideSwitch: true
        } as RouteMeta
      },
      {
        path: 'federations/:id/edit',
        name: 'FederationBackoffice',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/FederationBackoffice.vue'),
        meta: {
          auth: true,
          layout: LayoutMode.Backoffice
        } as RouteMeta
      },
      {
        path: 'organizations/:id/edit',
        name: 'OrganizationBackoffice',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/OrganizationBackoffice.vue'),
        meta: {
          auth: true,
          layout: LayoutMode.Backoffice
        } as RouteMeta
      },
      {
        path: 'trainers/:id/edit',
        name: 'TrainerBackoffice',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/TrainerBackoffice.vue'),
        meta: {
          auth: true,
          layout: LayoutMode.Backoffice
        } as RouteMeta
      },
      {
        path: 'athletes/:id/edit',
        name: 'AthleteBackoffice',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/AthleteBackoffice.vue'),
        meta: {
          auth: true,
          layout: LayoutMode.Backoffice
        } as RouteMeta
      },
      {
        path: ':entity',
        name: 'EntityList',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/EntityList.vue'),
        meta: {
          auth: true,
          layout: LayoutMode.Frontend,
          showTopSearch: true,
          hideBreadcrumb: true
        } as RouteMeta
      },
      {
        path: 'search',
        name: 'SearchResult',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/SearchResult.vue'),
        meta: {
          auth: true,
          layout: LayoutMode.Frontend,
          showTopSearch: true,
          hideBreadcrumb: true
        } as RouteMeta
      },
      {
        path: 'next/competitions',
        name: 'NextCompetition',
        component: () => import(/* webpackChunkName: "Logout" */ '../pages/NextCompetition.vue'),
        meta: {
          auth: true,
          layout: LayoutMode.Frontend,
          showTopSearch: true,
          hideBreadcrumb: true
        } as RouteMeta
      }
    ]
  }
]

const router = createRouter({
	history: createWebHistory(),
	routes,
  scrollBehavior() {
    const el = document.getElementById('app');
    if (el != null) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }
});

router.beforeEach((to, from, next) => {
  // If next router require authentication
  // And user is logged in, continue with execution
  // Otherwise return to login
  const localeIncluded = i18nSupportedLanguages.includes(to.params.locale as string);
  
  if (AuthService.isLoggedIn()) {
    if (to.meta && to.meta.auth == null) {
      return next(localizePath({
        path: '/'
      }));
    } 
  } else {
    if (localeIncluded && to.meta && to.meta.auth === true) {
      console.log('login')
      return next(localizePath({ path: '/login' }));
    }
  }

  if (!localeIncluded) {
    return next(localizePath({
      path: to.path,
      params: {
        locale: i18n.global.locale
      },
      query: to.query
    }));
  } else {
    return next();
  }
});

export default router;
