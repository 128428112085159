
export default {
	tabs: {
		home: 'Home',
		federations: 'Federazioni',
		organizations: 'Società',
		trainers: 'Allenatori',
		athletes: 'Atleti',
	},
	pagination: {
		page: 'Pagina',
		of: 'di',
	},
	login: {
		title: 'Bentornato',
		subtitle: 'Effettua il login per accedere',
		form: {
			email: {
				label: 'Indirizzo Email',
				placeholder: 'Email',
			},
			password: {
				label: 'Password',
				placeholder: '******',
			},
		},
		button: 'Accedi',
	},
	firstReset: {
		title: 'Email verificata con successo',
		subtitle: 'Imposta la tua password e accedi al CONS',
		button: 'Imposta password',
	},
	common: {
		information: 'Informazioni',
		federations: 'federazioni',
		organizations: 'società',
		trainers: 'allenatori',
		athletes: 'atleti',
		competitions: 'competizioni',
		bio: 'Biografia',
		staff: 'Direttivo',
		nationals: 'Nazionali',
		sportClubs: 'Società sportive',
		ranking: 'Ranking',
		internalCode: 'Codice Internazionale Atleta',
		rankingPosition: 'Posizione attuale ranking Internazionale',
		bestRankingPosition: 'Miglior posizione ottenuta',
		oldPassword: 'Vecchia password',
		newPassword: 'Nuova password',
		confirmNewPassword: 'Conferma nuova passowrd',
		resetPasswordButton: 'Modifica password',
		showMoreResults: 'Mostra più risultati',
		gold: 'oro',
		silver: 'silver',
		bronze: 'bronzo',
		contact: 'Contatti',
		noResults: 'Nessun risultato da mostrare',
		noTrainers: 'Nessun allenatore da mostrare',
		noAthletes: 'Nessun atleta da mostrare',
		noSportClubs: 'Nessuna società da mostrare',
		noNationals: 'Nessuna nazionale da mostrare',
		noDetails: 'Nessun dettaglio da mostrare',
		noStaffs: 'Nessun direttivo da mostrare',
		showAll: 'Mostra tutto',
		notes: 'Note',
		opponent: "Avversario",
		result: "Risultato",
		results: "Risultati",
		findDots: 'Cerca...',
		searchResult: 'Risultati della ricerca',
		tabs: {
			info: 'Informazioni',
			national: 'Nazionali',
			federations: 'Federazioni',
			organization: 'Società sportive',
			trainer: 'Allenatori',
			athlete: 'Atleti',
			staff: 'Direttivo',
			result: 'Risultati',
			ranking: 'Ranking',
			otherResult: 'Altri risultati',
			discipline: 'Discipline',
			resultType: 'Tipologia risultato',
			sport: 'Sport',
			staffRole: 'Ruoli staff',
			trainerRole: 'Ruoli allenatore',
			internationalCompetition: 'Competizioni internazionali',
			visitor: 'Gestisci ospiti'
		},
		bioItems: {
			foundation: 'Fondazione',
			website: 'Sito web',
			email: 'Email',
			telephone: 'Telefono',
		},
		fields: {
			deleted: 'Stato',
			deletedSubtitle: 'Attiva questa opzione per rendere visibile il profilo',
			active: 'Non più in attività',
			shortname: 'Abbreviazione',
			shortnameMin: 'Abbr.',
			website: 'Sito web',
			telephone: 'Telefono',
			publicEmail: 'Mostra email',
			cover: 'Copertina',
			publicEmailSubtitle:
				'Attiva questa opzione per rendere visibile il tuo indizzo email',
			publicTelephone: 'Mostra telefono',
			publicTelephoneSubtitle:
				'Attiva questa opzione per rendere visibile il tuo telefono',
			fullname: 'Nome completo',
			name: 'Nome',
			role: 'Ruolo',
			action: 'Azioni',
			isNational: 'Federale',
			firstname: 'Nome',
			lastname: 'Cognome',
			period: 'Durata',
			location: 'Luogo',
			startedAt: 'Data di inizio',
			endedAt: 'Data di fine',
			addAccount: 'Aggiungi account',
			addNew: 'Aggiungi nuovo',
			competitionResultType: 'Tipo risultato',
			rankingPosition: 'Posizione ranking',
			bestRankingPosition: 'Miglior posizione ranking',
			rankingLink: 'Link ranking',
			haveMedals: 'Ha medaglie?'
		},
		placeholders: {
			inputCompetition: 'Inserisci competizione',
			selectCompetition: 'Seleziona competizione',
			sport: 'Seleziona sport',
			discipline: 'Seleziona disciplina',
			position: 'Posizione',
			totPartecipant: 'Tot',
			competitionResultType: 'Seleziona tipologia risultato',
		},
		operations: {
			add: 'Aggiungi',
			edit: 'Modifica',
			preview: 'Anteprima',
			remove: 'Rimuovi',
			save: 'Salva',
			close: 'Chiudi',
			back: 'Indietro',
			addIntermiadiate: 'Aggiungi intermedi',
			filter: 'Filtra',
			openMenu: 'Apri Menu',
			closeMenu: 'Chiudi Menu',
		},
		modals: {
			staff: {
				title: 'Aggiungi responsabile',
			},
			createOrganization: {
				title: 'Aggiungi società',
			},
			createFederation: {
				title: 'Aggiungi federazione',
			},
			createVisitor: {
				title: 'Aggiungi ospiti',
			},
			createTrainer: {
				title: 'Aggiungi allenatore',
			},
			createAthlete: {
				title: 'Aggiungi atleta',
			},
			createCompetition: {
				title: 'Aggiungi competizione',
			},
			createInternationalCompetition: {
				title: 'Aggiungi competizione internazionale',
			},
			createTag: {
				title: 'Aggiungi tag',
			},
			deleteEntity: {
				title:
					"Sei sicuro di voler eliminare :name:? L'unico modo per recuperare questi dati sarà tramite un super admin",
			},
			sportModal: {
				titleCreate: 'Crea sport',
				titleUpdate: 'Modifica sport',
			},
			disciplineModal: {
				titleCreate: 'Crea disciplina',
				titleUpdate: 'Aggiorna disciplina',
			},
			staffRoleModal: {
				titleCreate: 'Crea ruolo staff',
				titleUpdate: 'Aggiorna ruolo staff',
			},
			trainerRoleModal: {
				titleCreate: 'Crea ruolo allenatore',
				titleUpdate: 'Aggiorna ruolo allenatore',
			},
			manageAccount: {
				title: 'Gestisci account'
			}
		},
	},
	home: {
		staticSearch: 'Cerca tra',
		loopSearchStrings: {
			federations: 'federazioni',
			organizations: 'società',
			trainers: 'allenatori',
			athletes: 'atleti',
		},
		searchBarPlaceholder: 'Cerca nel portale...',
		stats: {
			title: `<span class='lead text-body'><b>STATS</b> by Comitato Olimpico Nazionale Sammarinese è il portale di riferimento per 
            ogni informazione sulle Federazioni Sportive di San Marino</span>`,
		},
		nextCompetition: {
			title: 'Prossime competizioni',
			text: 'Scopri gli eventi e competizioni in programma',
			button: 'Visualizza',
		},
		federations: {
			title: 'Esplora per Federazione',
			goTo: 'Vai',
		},
	},
	athlete: {
		table: {
			name: 'Nome',
			organization: 'Società',
			trainer: 'Allenatore',
		},
	},
	result: {
		position: 'Posizione',
		intermiadiate: 'Risultati intermedi',
		attachments: 'Allegati',
	},
	account: {
		details: 'Dettagli',
		visitors: 'Ospiti',
		personalData: {
			title: 'Dati personali',
			subtitle: 'Gestisci la tua password',
		},
		profileManaged: {
			title: 'Profili gestiti',
			subtitle: 'Seleziona il profilo da modificare',
			table: {
				name: 'Nome',
				action: 'Azioni',
			},
		},
		manageMetadata: {
			title: 'Gestisci metadata',
			subtitle: '',
		},
	},
	fields: {
		profilePicture: 'Foto profilo',
	},
	backoffice: {
		sideNavTabs: {
			details: {
				title: 'Dettagli',
				information: 'Informazioni',
				executive: 'Direttivo',
			},
			contents: {
				title: 'Contenuti',
				sportClubs: 'Società sportive',
				trainers: 'Staff tecnico',
				athletes: 'Atleti',
				competitions: 'Competizioni',
				results: 'Risultati',
			},
			management: {
				title: 'Gestione',
				account: 'Account',
			},
			subtitles: {
				executive: 'Gestisci i responsabili della',
				sportClub: 'Gestisci le società sportive della',
				trainers: 'Gestisci staff tecnico della',
				athletes: 'Gestisci atleti della',
				competitions: 'Gestisci competizioni della federazione',
				tag: 'Gestisci i tag della società',
				results: 'Gestisci risultati per',
				manageMetadata: 'Gestisci tutti i metadati',
				manageFederation: 'Gestisci federazioni',
				intCompetition: 'Gestisci competizioni internazionali',
				manageVisitor: 'Gestisci ospiti'
			},
		},
	},
	validators: {
		required: 'Questo campo è obbligatorio',
		confirmPassordNotMatch: 'Conferma nuova password non uguale',
		multiRequired: 'Campo obbligatorio nella lingua selezionata',
		email: 'email non valida',
		hasPosition: 'Risultato principale richiesto',
		teamResultHaveEntity: "Selezionare almeno un'entità a cui associare il risultato"
	},
	messages: {
		passwordReset: 'Password modificata con successo!',
	},
	results: {
		competition: 'Competizione',
		sport: 'Sport',
		discipline: 'Disciplina',
		organizations: 'Società',
		trainers: 'Allenatori',
		athletes: 'Atleti',
		resultCategory: 'Categoria risultato',
		position: 'Posizione'
	},
	footer: {
		copyright: '© CONS 2022 - Tutti i diritti riservati',
	},
};
