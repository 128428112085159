
export default {
	tabs: {
		home: 'Home',
		federations: 'Federations',
		organizations: 'Organizations',
		trainers: 'Trainers',
		athletes: 'Athletes',
	},
	pagination: {
		page: 'Page',
		of: 'of',
	},
	login: {
		title: 'Welcome',
		subtitle: 'Login for access',
		form: {
			email: {
				label: 'Email Address',
				placeholder: 'Email',
			},
			password: {
				label: 'Password',
				placeholder: '******',
			},
		},
		button: 'Login',
	},
	firstReset: {
		title: 'Email verified successfully',
		subtitle: 'Reset your password and access CONS',
		button: 'Reset password',
	},
	common: {
		information: 'Information',
		federations: 'federations',
		organizations: 'sport clubs',
		trainers: 'trainers',
		athletes: 'athletes',
		competitions: 'competitions',
		bio: 'Biography',
		staff: 'Executive',
		nationals: 'Nationals',
		sportClubs: 'Sport Clubs',
		ranking: 'Ranking',
		internalCode: 'International athlete code',
		rankingPosition: 'Current International ranking position',
		bestRankingPosition: 'Best ranking position',
		oldPassword: 'Old password',
		newPassword: 'New password',
		confirmNewPassword: 'Confirm new password',
		resetPasswordButton: 'Update password',
		showMoreResults: 'Show more results',
		gold: 'gold',
		silver: 'silver',
		bronze: 'bronze',
		contact: 'Contacts',
		noResults: 'No results to show',
		noTrainers: 'No trainers to show',
		noAthletes: 'No athletes to show',
		noSportClubs: 'No sport clubs to show',
		noNationals: 'No nationals to show',
		noStaffs: 'No executive to show',
		noDetails: 'No details to show',
		showAll: 'Show all',
		notes: 'Notes',
		opponent: 'Opponent',
		result: 'Result',
		results: "Results",
		findDots: 'Find...',
		searchResult: 'Search results',
		tabs: {
			info: 'Information',
			national: 'Nationals',
			federations: 'Federations',
			organization: 'Sport clubs',
			trainer: 'Trainers',
			athlete: 'Athletes',
			staff: 'Executive',
			result: 'Results',
			ranking: 'Ranking',
			otherResult: 'Other results',
			discipline: 'Discipline',
			resultType: 'Result type',
			sport: 'Sport',
			staffRole: 'Staff role',
			trainerRole: 'Trainer role',
			internationalCompetition: 'International competition',
			visitor: 'Manage visitors'
		},
		bioItems: {
			foundation: 'Foundation',
			website: 'Website',
			email: 'Email',
			telephone: 'Telephone',
		},
		fields: {
			deleted: 'State',
			deletedSubtitle: 'Enable this option for show profile',
			active: 'No longer in activity',
			shortname: 'Shortname',
			shortnameMin: 'Abbr.',
			website: 'Website',
			telephone: 'Telephone',
			publicEmail: 'Show email',
			publicEmailSubtitle: 'Enable this option for show email',
			publicTelephone: 'Show telephone',
			publicTelephoneSubtitle: 'Enable this option for show telephone',
			fullname: 'Fullname',
			name: 'Name',
			role: 'Role',
			cover: 'Cover',
			action: 'Action',
			isNational: 'Federal',
			firstname: 'Firstname',
			lastname: 'Lastname',
			period: 'Period',
			location: 'Location',
			startedAt: 'Started at',
			endedAt: 'Ended at',
			addAccount: 'Add account',
			addNew: 'Add new',
			competitionResultType: 'Competition result',
			rankingPosition: 'Ranking position',
			bestRankingPosition: 'Best ranking position',
			rankingLink: 'Link ranking',
			haveMedals: 'Have medals?'
		},
		placeholders: {
			inputCompetition: 'Insert competition',
			selectCompetition: 'Select competition',
			sport: 'Select sport',
			discipline: 'Select discipline',
			position: 'Position',
			totPartecipant: 'Tot',
			competitionResultType: 'Select competition result type',
		},
		operations: {
			add: 'Add',
			edit: 'Edit',
			preview: 'Preview',
			remove: 'Remove',
			save: 'Save',
			close: 'Close',
			back: 'Back',
			addIntermiadiate: 'Add intermediates',
			filter: 'Filter',
			openMenu: 'Open Menu',
			closeMenu: 'Close Menu',
		},
		modals: {
			staff: {
				title: 'Add executive',
			},
			createFederation: {
				title: 'Add federation',
			},
			createVisitor: {
				title: 'Add visitor',
			},
			createOrganization: {
				title: 'Add sport club',
			},
			createTrainer: {
				title: 'Add trainer',
			},
			createAthlete: {
				title: 'Add athlete',
			},
			createCompetition: {
				title: 'Add competition',
			},
			createInternationalCompetition: {
				title: 'Add international competition',
			},
			createTag: {
				title: 'Add tag',
			},
			deleteEntity: {
				title:
					'Are you sure you want to delete :name:? The only way for restore this data is by contact super administrator',
			},
			sportModal: {
				titleCreate: 'Add sport',
				titleUpdate: 'Update sport',
			},
			disciplineModal: {
				titleCreate: 'Add discipline',
				titleUpdate: 'Update discipline',
			},
			staffRoleModal: {
				titleCreate: 'Add staff role',
				titleUpdate: 'Update staff role',
			},
			trainerRoleModal: {
				titleCreate: 'Add trainer role',
				titleUpdate: 'Update trainer role',
			},
			manageAccount: {
				title: 'Manage account'
			}
		},
	},
	home: {
		staticSearch: 'Find from',
		loopSearchStrings: {
			federations: 'federations',
			organizations: 'organizations',
			trainers: 'trainers',
			athletes: 'athletes',
		},
		searchBarPlaceholder: 'Find on website...',
		stats: {
			title: `<span class='lead text-body'><b>STATS</b> by San Marino National Olympic Committee is the reference portal for
            any information on the Sports Federations of San Marino</span>`,
		},
		nextCompetition: {
			title: 'Next competition',
			text: 'Find scheduled events and competitions',
			button: 'Show',
		},
		federations: {
			title: 'Explore for Federation',
			goTo: 'Go',
		},
	},
	athlete: {
		table: {
			name: 'Name',
			organization: 'Organization',
			trainer: 'Trainer',
		},
	},
	result: {
		position: 'Position',
		intermiadiate: 'Intermiadiate results',
		attachments: 'Attachments',
	},
	account: {
		details: 'Details',
		visitors: 'Visitors',
		personalData: {
			title: 'Personal data',
			subtitle: 'Manage your password',
		},
		profileManaged: {
			title: 'Profile managed',
			subtitle: 'Select profile type',
			table: {
				name: 'Name',
				action: 'Action',
			},
		},
		manageMetadata: {
			title: 'Manage metadata',
		},
	},
	fields: {
		profilePicture: 'Profile picture',
	},
	backoffice: {
		sideNavTabs: {
			details: {
				title: 'Details',
				information: 'Information',
				executive: 'Executive',
				tag: 'Tag',
			},
			contents: {
				title: 'Contents',
				sportClubs: 'Sport clubs',
				trainers: 'Trainers',
				athletes: 'Athletes',
				competitions: 'Competitions',
				results: 'Results',
			},
			management: {
				title: 'Management',
				account: 'Account',
			},
			subtitles: {
				executive: 'Manage executive of',
				sportClub: 'Manage sport clubs of',
				trainers: 'Manage trainers of',
				athletes: 'Manage athletes of',
				competitions: 'Manage competitions of federation',
				tag: 'Manage tag of organization',
				results: 'Manage results of',
				manageMetadata: 'Manage all metadata',
				manageFederation: 'Manage federations',
				intCompetition: 'Manage international competitions',
				manageVisitor: 'Manage visitors'
			},
			
		},
	},
	validators: {
		required: 'This field is required',
		confirmPassordNotMatch: 'Confirm new password not match',
		multiRequired: 'Your language field is required',
		email: 'Email not valid',
		hasPosition: 'Main position is required',
		teamResultHaveEntity: "Minimun one entity is required for result"
	},
	messages: {
		generalSuccess: {
			create: 'Created successfully!',
			update: 'Updated successfully!',
			save: 'Saved successfully',
		},
		generalError: {
			create: 'Create error: check data and retry!',
			update: 'Update error: check data and retry',
			save: 'Saved error: check data and retry!',
		},
		login: {
			userNotFound: 'User not found',
			passwordNotMatch: 'Bad password',
		},
		passwordReset: {
			success: 'Your password successfully resetted!',
			notMatch: 'Password not match',
		},
	},
	results: {
		competition: 'Competition',
		sport: 'Sport',
		discipline: 'Discipline',
		organizations: 'Organizations',
		trainers: 'Trainers',
		athletes: 'Athletes',
		resultCateogry: 'Result category',
		position: 'Position'

	},
	footer: {
		copyright: '© CONS 2022 - All rights reserved',
	},
};