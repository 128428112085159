
	import { computed, defineComponent, inject, onMounted, ref } from 'vue';
	import { MrUiKitBreadcrumb } from 'mr-ui-kit';
	import TopSearch from '@/components/UI/TopSearch.vue';

	import { useRoute, useRouter } from 'vue-router';
	import { BreadcrumbInstance, LayoutMode, RouteEntity } from '@/interfaces';
import { localizePath, useI18n } from '@/plugins/i18n';
import { useStore } from 'vuex';

	export default defineComponent({
		name: 'FrontendLayout',
		components: {
			MrUiKitBreadcrumb,
			TopSearch,
		},
		setup() {
			const { t } = useI18n();
			const route = useRoute();
			const router = useRouter();
			const store = useStore();

			const layoutConfig = computed(() => {
				return {
					hideNavBar: route.meta.hideNavBar,
					isNavBarSoft: route.meta.isNavBarSoft,
					showTopSearch: route.meta.showTopSearch,
					hideBreadcrumb: route.meta.hideBreadcrumb,
					layout: route.meta.layout as LayoutMode,
				};
			});

			const onNavigateBreadcrumb = ({ itemIndex, path }: any) => {
				store.commit('subBreadcrumb', itemIndex);
				router.push(localizePath({ path }));
			};

			const items = computed(() => store.getters.breadcrumbItems);
			
			const canUpdateEntity = computed(() => {
				const current = items.value[items.value.length - 1];
				if (current != null && current.data) {
					return current.data[current.type].permission === 'C,R,U,D';
				}

				return false
			})

			const goToBackoffice = () => {
				if (canUpdateEntity) {
					store.commit('switchBreadcrumb', 'backoffice');
					router.push({ path: route.path + '/edit' });
				}
			}

			return {
				t,
				layoutConfig,
				items,
				canUpdateEntity,
				onNavigateBreadcrumb,
				goToBackoffice
			};
		},
	});
