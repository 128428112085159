import { createI18n, useI18n } from 'vue-i18n';
import messages from '@/locales/i18n';
import router from '@/router';
import { computed } from 'vue';

const defaultI18nLang = "it"; 

const i18n = createI18n({
	globalInjection: true,
	legacy: true,
	locale: defaultI18nLang,
	fallbackLocale: 'en',
	messages,
});

function localizePath(to: any): any {
    if (typeof to === 'string') {
        return i18n.global.locale + '/' + to;
    } else {
        return {
            ...to,
            path: '/' + i18n.global.locale + to.path
        }
    }
}

function switchI18nLanguage(locale: string) {
    const to = router.resolve({
        params: {
            locale
        }
    });
    router.push(to);
}

const i18nLanguages = [
    {
        locale: 'it',
        name: 'Italiano',
        label: 'IT'
    },
    {
        locale: 'en',
        name: 'English',
        label: 'EN'
    }
]

const i18nSupportedLanguages = i18nLanguages.map(l => l.locale);

const currentI18nLanguage = computed(() => {
    const current = i18nLanguages.find(language => language.locale === i18n.global.locale); 
    if (current == null) {
        return {
            locale: defaultI18nLang,
            name: 'Italiano',
            label: 'IT',
            value: defaultI18nLang
        };
    }
    return {
        ...current,
        value: current.locale
    };
})

export default i18n;
export { defaultI18nLang}
export { useI18n }
export { i18n }
export { localizePath }
export { switchI18nLanguage }
export { i18nSupportedLanguages }
export { i18nLanguages }
export { currentI18nLanguage }