import { SetupResponse } from "@/interfaces";
import axios, { AxiosResponse } from "axios";

class SetupService {
    constructor() {}

    async setup(): Promise<SetupResponse> {
        const { data }: AxiosResponse<SetupResponse> = await axios.get('/setup');
        return data;
    }
}

export default new SetupService();