import { BreadcrumbItem, CompetitionResultType, SetupResponse } from ".";

export enum LayoutMode {
    None = 'None',
    Frontend = 'Frontend',
    Backoffice = 'Backoffice'
}

export type GeneralFormatted = {
    id: number;
    name: string;
}

export interface Sport {
    id: number;
    name: string;
    disciplines: GeneralFormatted[];
}

export interface Discipline {
    id: number;
    name: string;
    sport: Sport;
}

export type AlertCfg = {
    toggle: boolean;
    message: string | null;
    type: 'success' | 'danger' | null;
}

export interface RootState extends SetupResponse {
    layoutMode: LayoutMode;
    isLoading: boolean;
    sports: Sport[];
    staffRoles: GeneralFormatted[];
    trainerRoles: GeneralFormatted[];
    competitionResultTypes: CompetitionResultType[];
    alert: AlertCfg;
    isSuperAdmin: boolean;
    isVisitor: boolean;
    breadcrumb: {
        rebuild: boolean;
        history: BreadcrumbItem[];
        backofficeMenuPath: BreadcrumbItem;
        frontendMenuPath: BreadcrumbItem;
        current: number;
    };
}