
	import { computed, defineComponent, onMounted } from 'vue';
	import { MrUiKitBreadcrumb, MrUiKitSideNavToggle } from 'mr-ui-kit';
	import { LayoutMode } from '@/interfaces';
	import { useRoute, useRouter } from 'vue-router';
	import { localizePath } from '@/plugins/i18n';
	import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

	export default defineComponent({
		name: 'BackofficeLayout',
		components: {
			MrUiKitBreadcrumb,
			MrUiKitSideNavToggle,
		},
		setup() {
			const route = useRoute();
			const router = useRouter();
			const store = useStore();
			const { t } = useI18n();

			const layoutConfig = computed(() => {
				return {
					hideNavBar: route.meta.hideNavBar,
					isNavBarSoft: route.meta.isNavBarSoft,
					showTopSearch: route.meta.showTopSearch,
					hideBreadcrumb: route.meta.hideBreadcrumb,
					layout: route.meta.layout as LayoutMode,
					hideSwitch: route.meta.hideSwitch
				};
			});

			const onNavigateBreadcrumb = ({ itemIndex, path }: any) => {
				store.commit('subBreadcrumb', itemIndex);
				router.push(localizePath({ path }));
			};

			const items = computed(() => store.getters.breadcrumbItems);

			const currentBreadcrumb = computed(() => items.value[items.value.length - 1]);

			const goToFrontend = () => {
				router.push({ path: route.path.replace('/edit', '') })
				store.commit('switchBreadcrumb', 'frontend');
			}

			return {
				layoutConfig,
				items,
				currentBreadcrumb,
				onNavigateBreadcrumb,
				t,
				goToFrontend
			};
		},
	});
