export enum RouteEntity {
    Federation = 'federations',
    Organization = 'organizations',
    Trainer = 'trainers',
    Athlete = 'athletes',
    Staff = 'staff',
    SportClub = 'sport-clubs',
    Tag = 'tags',
    Competition = 'competitions'
}

export type BioItem = {
    title: string;
    value: string | null;
    visible?: boolean;
    slot?: string;
}

export type SocialItem = {
    facebook?: string;
    instagram?: string;
}

export interface PaginationModel {
    current: number;
    count: number;
    limit: number;
}