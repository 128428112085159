export enum PermissionType {
    CREATE = 'C',
    READ = 'R',
    UPDATE = 'U',
    DELETE = 'D'
}

export interface GeneralPermission {
    FA?: string; // could be C,R,U,D
    SC?: string;
    AL?: string;
    AT?: string;
    CN?: string;
    VT?: string;
    CM?: string; // Competition
    permission?: string;
}

export type PermissionEntityType = keyof GeneralPermission;
export type PermissionOptions = {
    entity: PermissionEntityType;
    entityId: number;
    user: any;
}