import { Credentials, FirstResetPasswordBody, LayoutMode, LocalStorageKeys, Tokens, VerifyEmail } from "@/interfaces";
import i18n, { localizePath } from "@/plugins/i18n";
import router from '@/router';
import store from '@/store';
import axios from 'axios';

class AuthService {
    constructor() {}

    getToken(): string | null {
        return localStorage.getItem(LocalStorageKeys.TOKEN_KEY);
    }

    getRefreshToken(): string | null {
        return localStorage.getItem(LocalStorageKeys.REFRESH_KEY);
    }

    setToken(token: string): void {
        localStorage.setItem(LocalStorageKeys.TOKEN_KEY, token);
    }

    setRefreshToken(token: string): void {
        localStorage.setItem(LocalStorageKeys.REFRESH_KEY, token);
    }

    isLoggedIn(): boolean {
        return this.getToken() != null;
    }

    resetTokens(): void {
        localStorage.removeItem(LocalStorageKeys.TOKEN_KEY);
        localStorage.removeItem(LocalStorageKeys.REFRESH_KEY);
    }

    logout(): void {
        this.resetTokens();
        router.push(localizePath({ path: '/login' }));
        store.commit('setLayoutMode', LayoutMode.None);
    }

    async refreshToken(): Promise<Tokens> {
        const refreshToken = this.getRefreshToken();
        if (refreshToken) {
            try {
                const response = await axios.post<Tokens>('/users/refresh-token', {
                    refreshToken
                });
                const { data } = response;
                this.setToken(data.token);
                this.setRefreshToken(data.refreshToken);
                return data;
            } catch (err: any) {
                throw new Error('Refresh token not found');
            }
        } else {
            throw new Error('Refresh token not found');
        }
    }

    async login(creds: Credentials): Promise<Tokens> {
        const response = await axios.post<Tokens>('/users/login', creds);
        const { data } = response;
        this.setToken(data.token);
        this.setRefreshToken(data.refreshToken);
        store.commit('setLayoutMode', LayoutMode.Frontend);
        store.dispatch('setup', i18n.global.locale);
        router.push(localizePath({ path: '/' }));
        return data;
    }

    async verifyEmail(token: string): Promise<VerifyEmail> {
        const { data } = await axios.post<VerifyEmail>('/users/verify-email', { token });
        return data;
    }

    async firstReset(body: FirstResetPasswordBody): Promise<void> {
        const { data } = await axios.post<Tokens>('/users/first-reset', body);
        this.setToken(data.token);
        this.setRefreshToken(data.refreshToken);
        store.commit('setLayoutMode', LayoutMode.Frontend);
        router.push(localizePath({ path: '/' }));
    }
}

export default new AuthService();